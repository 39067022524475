import React, { useEffect, useState } from "react"
import { Dots, Spinner } from 'react-activity';
import * as url from "../../helpers/url_helper"
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";
import { useParams } from "react-router-dom";
import Spinners from "components/Common/Spinner"
import "flatpickr/dist/themes/material_blue.css"


const object = {
    loading: false,
    errors: {},
    ResErrors: {},
    isRedirect: false,
    sangathanId: null,
    assignRole: "",
    status: "true",
    name: "",
    place_id: "",
    optionalFieldAllow: null,
    selectedLocation: "",
    addressComponent: null,
    Locations: [],
    id: "",
    defaultAddress: "",

    countries: [],
    stateList: [],
    districtList: [],
    CityList: [],

    selectedCity: "",
    selectedCountry: "",
    selectedDistrict: "",
    selectedState: "",

    country: "",
    state: "",
    district: "",
    city: "",
    countryDataCode: "",
    stateCode: "",
    districtCode: "",
    cityCode: "",
};

const LocationAdd = (props) => {
    document.title = "Location - VUF";
    const { id } = useParams();
    const [countries, setcountries] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [cityList, setcityList] = useState([]);
    const [Locations, setLocations] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const [selectedCity, setselectedCity] = useState({});
    const [selectedCountry, setselectedCountry] = useState({});
    const [selectedDistrict, setselectedDistrict] = useState({});
    const [selectedState, setselectedState] = useState({});


    const [state, setstate] = useState(object);
    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "Location", link: "#" }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)

    })
    useEffect(() => {
        if (id !== '' && id != null && id != undefined) {
            getLocation();
        } else {
            onGetCountryList();
        }
    }, []);

    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    function handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (state.name?.trim() === "") {
            formIsValid = false;
            errors.name = "Please enter event Name";
        }
        setstate((prevState) => ({
            ...prevState, errors: errors
        }));

        return formIsValid;
    }
    function getLocation() {
        setLoading(true)
        let urlPath = "location/GetLocationDetails/" + id
        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                if (responseData.status) {
                    var datares = responseData.data
                    console.log(datares.name);
                    setstate((prevState) => ({
                        ...prevState, // Copy the existing state
                        id: id,
                        eventName: datares.name,
                        name: datares.name,
                        place_id: datares.place_id,
                        status: datares.status,
                        optionalFieldAllow: datares.optionalFieldAllow,
                        country: datares.country,
                        state: datares.state,
                        district: datares.district,
                        city: datares.city,
                        selectedCountry: datares.country,
                        selectedState: datares.state,
                        selectedDistrict: datares.district,
                        selectedCity: datares.city,
                        countryDataCode: datares.countryDataCode,
                        stateCode: datares.stateCode,
                        districtCode: datares.districtCode,
                        cityCode: datares.cityCode,
                        addressComponent: datares.addressComponent
                    }));
                    setselectedCountry({ label: datares.country, value: 0 })
                    setselectedState({ label: datares.state, value: 0 })
                    setselectedDistrict({ label: datares.district, value: 0 })
                    setselectedCity({ label: datares.city, value: 0 })

                    onGetCountryList();
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }
    function onGetCountryList() {

        let urlPath = "auth/CountryList"

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = [];
                    countries.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        if (selectedCountry.label == c.country_name && selectedCountry.value == 0) {
                            console.log(c.country_name)
                            setselectedCountry({ ...c, label: c.country_name, value: c.id })
                            onGetStateList(c.id)
                        }
                        countries.push({ ...c, label: c.country_name, value: c.id })
                    })
                    const cot = [{ "options": countries }]
                    setcountries(cot)
                    // countries.forEach(c => {
                    //     if(c.label==state.country){
                    //         setselectedCountry(c)
                    //         onGetStateList(c.value)
                    //     }
                    // });




                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetStateList(data) {
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            countryDataCode: data
        }))
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;
                        let stateList = []
                        stateList.push({ label: "--Select--", value: "" })
                        data.map((c, i) => {
                            if (selectedState.label == c.state_title && selectedState.value == 0) {
                                setselectedState({ ...c, label: c.state_title, value: c.state_id })
                                onGetDistrictList(c.state_id)
                            }
                            stateList.push({ ...c, label: c.state_title, value: c.state_id })
                        })
                        const cot = [{ "options": stateList }]
                        setstateList(cot);
                        // stateList.map((c)=>{
                        //     if(state.state==c.label){
                        //         setselectedState(c)
                        //         onGetDistrictList(c.value)
                        //     }
                        // })



                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                });
        }
    }
    function onGetDistrictList(data) {
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            stateCode: data
        }));

        let urlPath = "auth/DistrictsList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []
                    districtList.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        if (selectedDistrict.label == c.district_title && selectedDistrict.value == 0) {
                            setselectedDistrict({ ...c, label: c.district_title, value: c.districtid })
                            onGetCitiesList(c.districtid)
                        }
                        districtList.push({ label: c.district_title, value: c.districtid })
                    })

                    const cot = [{ "options": districtList }]
                    setdistrictList(cot)

                    // districtList.map((c)=>{
                    //     if(state.district==c.label){
                    //         setselectedDistrict(c)
                    //         onGetCitiesList(c.value)
                    //     }
                    // })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetCitiesList(data) {
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            districtCode: data // Update only the `districtCode` property
        }));
        let urlPath = "auth/CitiesList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []
                    cityList.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        if (selectedCity.label == c.name && selectedCity.value == 0) {
                            setselectedCity({ ...c, label: c.name, value: c.id })
                        }
                        cityList.push({ label: c.name, value: c.id })
                    })

                    const cot = [{ "options": cityList }]
                    setcityList(cot)

                    // cityList.map((c)=>{
                    //     if(state.city==c.label){
                    //         setselectedCity(c)
                    //     }
                    // })

                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }

    function onUserInvite(e) {

        const token = localStorage.getItem('token');
        if (!handleValidation()) {
            return false
        }
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })

        const Body = JSON.stringify({
            name: state.name,
            place_id: state.place_id,
            status: state.status,
            optionalFieldAllow: state.optionalFieldAllow,
            addressComponent: state.addressComponent,
            country: selectedCountry.label,
            state: selectedState.label,
            district: selectedDistrict.label,
            city: selectedCity.label,
            countryDataCode: state.countryDataCode,
            stateCode: state.stateCode,
            districtCode: state.districtCode,
            cityCode: state.cityCode,
            _id: id
        })


        setLoading(true)
        let urlPath = "location/create-location";
        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {
                    if (id == null || id == '' || id == undefined) {
                        alert("Location Successfully Added.")
                    } else {
                        alert("Location Successfully Updated.")
                    }


                    window.location.href = "/LocationList";
                    // this.redirectWithPath("/LocationList")
                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
            }).finally(final => {
                // return this.setState({ loading: false })
            });
    }


    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header" >
                                        {id == '' || id == null || id == undefined ?
                                            <h3 className="card-title" >Add Location</h3> :
                                            <h3 className="card-title" >Edit Location</h3>
                                        }
                                    </div>
                                    <div className="card-body row col-12" >

                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" name="name" className="form-control form-control-solid"
                                                            placeholder="Enter name"
                                                            onChange={(event) => { setstate((prevState) => ({ ...prevState, name: event.target.value })) }}
                                                            value={state.name} />
                                                        <span className="form-text text-danger">{state.errors?.name}</span>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Place ID</label>
                                                            <input type="text" name="place_id" className="form-control form-control-solid"
                                                                placeholder="Enter place id" onChange={(event) => Functions.onChange(event, this)} value={this.state.place_id} />
                                                            <span className="form-text text-danger">{this.state.errors?.place_id}</span>
                                                        </div>
                                                    </div> */}

                                                <div className='col-md-6'>


                                                    <label className="checkbox mt-4">
                                                        <input type="checkbox" name="OptionalField" onChange={(event) => { (state.optionalFieldAllow == true ? setstate((prevState) => ({ ...prevState, optionalFieldAllow: false })) : setstate((prevState) => ({ ...prevState, optionalFieldAllow: true }))) }}
                                                            value={state.optionalFieldAllow}
                                                            defaultChecked={state.optionalFieldAllow}
                                                        />
                                                        <span className='mr-3 ' > </span>Optional Field Allow</label>
                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Country / દેશ:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedCountry}
                                                            onChange={(e) => {
                                                                setselectedCountry(e);
                                                                setselectedDistrict({ label: "--Select--", value: "" });
                                                                setselectedCity({ label: "--Select--", value: "" });
                                                                setselectedState({ label: "--Select--", value: "" });
                                                                onGetStateList(e.value)
                                                            }}
                                                            options={countries}
                                                        />


                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>State / રાજ્ય:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedState}
                                                            onChange={(e) => {
                                                                setselectedState(e);
                                                                setselectedDistrict({ label: "--Select--", value: "" });
                                                                setselectedCity({ label: "--Select--", value: "" });
                                                                onGetDistrictList(e.value)
                                                            }}
                                                            options={stateList}
                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>District / જિલ્લો:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedDistrict}
                                                            onChange={(e) => { setselectedDistrict(e); setselectedCity({ label: "--Select--", value: "" }); onGetCitiesList(e.value) }}
                                                            options={districtList}
                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>City / શહેર:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedCity}
                                                            onChange={(e) => { setselectedCity(e); }}
                                                            options={cityList}
                                                        />
                                                        <span className="form-text text-danger">{state.errors?.selectedCity}</span>




                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                    <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                        <button type="button" className="btn btn-primary mb-2" onClick={(event) => onUserInvite(event)}>

                                            {id == '' || id == null || id == undefined ?
                                                <label> Add Location</label> :
                                                <label>Edit Location</label>
                                            }
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(LocationAdd);