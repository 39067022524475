import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";
import { useParams } from "react-router-dom";
import Spinners from "components/Common/Spinner"
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import { Link } from 'react-router-dom';
import moment from 'moment';
import Flatpickr from "react-flatpickr"
import { boolean } from "yup";

const Event = (props) => {
    document.title = "Event - VUF";
    const { id } = useParams();
    const [countries, setcountries] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [cityList, setcityList] = useState([]);
    const [Locations, setLocations] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const [selectedCity, setselectedCity] = useState({});
    const [selectedCountry, setselectedCountry] = useState({});
    const [selectedDistrict, setselectedDistrict] = useState({});
    const [selectedState, setselectedState] = useState({});

    const [selectedLocation, setselectedLocation] = useState({});

    const [state, setstate] = useState({
        loading: false,
        errors: {},
        ResErrors: {},
        isRedirect: false,
        sangathanId: null,
        assignRole: "",
        eventName: "",
        image: null,
        EventType: "",
        eventDescription: "",
        eventBannerImage: "",
        eventStartDate: "",
        eventEndDate: "",
        registrationStartDate: "",
        registrationEndDate: "",
        eventStartTime: "",
        eventEndTime: "",
        location: "",
        countries: [],
        stateList: [],
        districtList: [],
        CityList: [],
        MaxRegistration: 0,
        RegistrationOpen:boolean,
        selectedCity: {},
        selectedCountry: {},
        selectedDistrict: {},
        selectedState: {},

        country: "",
        state: "",
        district: "",
        city: "",
        countryDataCode: "",
        stateCode: "",
        districtCode: "",
        cityCode: "",
        IsCanceled: false,
        id: ""
    });
    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "Event", link: "#" }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)

    })
    useEffect(() => {
        if (id !== '' && id != null && id != undefined) {
            getEvent();
        } else {
            onGetLocationsList();
            onGetCountryList();
        }
    }, []);

    function getEvent() {
        setLoading(true)
        let urlPath = "event/eventdetails/" + id

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                if (responseData.status) {
                    var datares = responseData.data

                    setstate((prevState) => ({
                        ...prevState, // Copy the existing state
                        eventName: datares.eventName,
                        name: datares.eventName,
                        eventDescription: datares.eventDescription,
                        eventBannerImage: datares.eventBannerImage,
                        eventStartDate: moment(datares.eventStartDate).format("YYYY-MM-DD"),
                        eventEndDate: moment(datares.eventEndDate).format("YYYY-MM-DD"),
                        registrationStartDate: moment(datares.registrationStartDate).format("YYYY-MM-DD"),
                        registrationEndDate: moment(datares.registrationEndDate).format("YYYY-MM-DD"),
                        eventStartTime: datares.eventStartTime,
                        eventEndTime: datares.eventEndTime,
                        location: datares.location,

                        EventType: datares.EventType,
                        country: datares.country,
                        state: datares.state,
                        district: datares.district,
                        city: datares.city,
                        countryDataCode: datares.countryDataCode,
                        stateCode: datares.stateCode,
                        districtCode: datares.districtCode,
                        cityCode: datares.cityCode,
                        isCanceled: datares.isCanceled,
                        MaxRegistration: datares.MaxRegistration,
                        RegistrationOpen: datares.RegistrationOpen=="true"?true:false,
                        id: id
                    }));
                    setselectedLocation({ ...responseData.location, label: responseData.location.name, value: responseData.location.place_id })
                    setselectedCountry({ label: datares.country, value: 0 })
                    setselectedState({ label: datares.state, value: 0 })
                    setselectedDistrict({ label: datares.district, value: 0 })
                    setselectedCity({ label: datares.city, value: 0 })

                    onGetLocationsList();
                    onGetCountryList();
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // setState({ loading: false });
                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // setState({ loading: false });
            }).finally(final => {
                // return setState({ isInvitingUser: false })
            });

    }
    function onGetLocationsList() {
        setLoading(true)
        let urlPath = "DynamicPage/entry-locations/"
        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;

                    let loc = [];
                    data.map((c, i) => {
                        loc.push({ ...c, label: c.name, value: c.place_id })
                    })
                    const cot = [{ "options": loc }]
                    setLocations(cot)


                }
                setLoading(false)
            }).catch(error => {
            }).finally(final => {
            });
    }

    function onUserInvite(e) {

        if (!handleValidation()) {
            return false
        }

        const formData = new FormData();
        formData.append('_id', id);
        formData.append('eventName', state.eventName);
        formData.append('eventDescription', state.eventDescription);
        // if(image != null) {
        //     // formData.append('eventBannerImage', image);
        //     formData.append('image', image);
        //   }
        if (state.eventBannerImage != null) {
            formData.append('eventBannerImage', state.image);
            // formData.append('eventBannerImage', eventBannerImage);
        }
        formData.append('eventStartDate', state.eventStartDate != '' ? moment(state.eventStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('eventEndDate', state.eventEndDate != '' ? moment(state.eventEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('registrationStartDate', state.registrationStartDate != '' ? moment(state.registrationStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('registrationEndDate', state.registrationEndDate != '' ? moment(state.registrationEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('eventStartTime', state.eventStartTime);
        formData.append('eventEndTime', state.eventEndTime);
        formData.append('isCanceled', state.IsCanceled);

        formData.append('location', state.location);
        formData.append('EventType', state.EventType);
        formData.append('country', selectedCountry.label);
        formData.append('state', selectedState.label);
        formData.append('district', selectedDistrict.label);
        formData.append('city', selectedCity.label);
        formData.append('countryDataCode', state.countryDataCode);
        formData.append('stateCode', state.stateCode);
        formData.append('districtCode', state.districtCode);
        formData.append('cityCode', state.cityCode);

        formData.append('RegistrationOpen', state.RegistrationOpen);
        formData.append('MaxRegistration', state.MaxRegistration);

        let urlPath = "event/create-event";

        // return
        // postFormData(Constants.VUF_APP_API + urlPath, formData, { headers: { credentials: 'include', Accept: "*/*" } }).then((responseData) => {
        console.log(formData)
        setLoading(true)
        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Authorization': ""
            },
            body: formData,
            redirect: 'follow',
        }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);

                if (responseData.status) {
                    if (id == null || id == '' || id == undefined) {
                        alert("Event Successfully Added.")
                    } else {
                        alert("Event Successfully Updated.")
                    }
                    window.location.href = "/EventList";


                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )

            }).finally(final => {
                // return setState({ isInvitingUser: false })
            });
    }
    function onGetCountryList() {

        let urlPath = "auth/CountryList"

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    console.log(selectedCountry)
                    let countries = [];
                    countries.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        if (selectedCountry.label == c.country_name && selectedCountry.value == 0) {
                            console.log(c.country_name)
                            setselectedCountry({ ...c, label: c.country_name, value: c.id })
                            onGetStateList(c.id)
                        }
                        countries.push({ ...c, label: c.country_name, value: c.id })
                    })
                    const cot = [{ "options": countries }]
                    setcountries(cot)
                    // countries.forEach(c => {
                    //     if(c.label==state.country){
                    //         setselectedCountry(c)
                    //         onGetStateList(c.value)
                    //     }
                    // });




                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetStateList(data) {
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            countryDataCode: data
        }))
        console.log(data)
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;
                        let stateList = []
                        stateList.push({ label: "--Select--", value: "" })
                        data.map((c, i) => {
                            if (selectedState.label == c.state_title && selectedState.value == 0) {
                                setselectedState({ ...c, label: c.state_title, value: c.state_id })
                                onGetDistrictList(c.state_id)
                            }
                            stateList.push({ ...c, label: c.state_title, value: c.state_id })
                        })
                        const cot = [{ "options": stateList }]
                        setstateList(cot);
                        // stateList.map((c)=>{
                        //     if(state.state==c.label){
                        //         setselectedState(c)
                        //         onGetDistrictList(c.value)
                        //     }
                        // })



                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                });
        }
    }
    function onGetDistrictList(data) {
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            stateCode: data
        }));

        let urlPath = "auth/DistrictsList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []
                    districtList.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        if (selectedDistrict.label == c.district_title && selectedDistrict.value == 0) {
                            setselectedDistrict({ ...c, label: c.district_title, value: c.districtid })
                            onGetCitiesList(c.districtid)
                        }
                        districtList.push({ label: c.district_title, value: c.districtid })
                    })

                    const cot = [{ "options": districtList }]
                    setdistrictList(cot)

                    // districtList.map((c)=>{
                    //     if(state.district==c.label){
                    //         setselectedDistrict(c)
                    //         onGetCitiesList(c.value)
                    //     }
                    // })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetCitiesList(data) {
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            districtCode: data // Update only the `districtCode` property
        }));
        let urlPath = "auth/CitiesList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []
                    cityList.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        if (selectedCity.label == c.name && selectedCity.value == 0) {
                            setselectedCity({ ...c, label: c.name, value: c.id })
                        }
                        cityList.push({ label: c.name, value: c.id })
                    })

                    const cot = [{ "options": cityList }]
                    setcityList(cot)

                    // cityList.map((c)=>{
                    //     if(state.city==c.label){
                    //         setselectedCity(c)
                    //     }
                    // })

                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (state.eventName?.trim() === "") {
            formIsValid = false;
            errors.eventName = "Please enter event Name";
        }
        setstate((prevState) => ({
            ...prevState, // Copy the existing state
            errors: errors
        }));

        return formIsValid;
    }
    function onSelectLocation(e) {

        let location = e.value;
        setselectedLocation(e)
        const foundItem = Locations[0].options.filter(item => item.value == location)[0];
        setstate((prevState) => ({
            ...prevState, // Copy the existing 
            stateoptionalFieldAllow: foundItem?.optionalFieldAllow, country: foundItem.country, state: foundItem.state, location: location, district: foundItem.district, city: foundItem.city,
            cityCode: foundItem.cityCode, countryDataCode: foundItem.countryDataCode, districtCode: foundItem.districtCode, stateCode: foundItem.stateCode
        }));
        setselectedCountry({ label: foundItem.country, value: 0 })
        setselectedState({ label: foundItem.state, value: 0 })
        setselectedDistrict({ label: foundItem.district, value: 0 })
        setselectedCity({ label: foundItem.city, value: 0 })

        countries[0].options.map((c) => {
            if (c.label == foundItem.country) {
                setselectedCountry({ label: c.label, value: c.id })
                onGetStateList(c.value)
            }

        })

    }
  
    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header" >
                                        {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                        {/* <h3 className="card-title"> {state.name ? 'Edit' : 'Add'} {state.catName}</h3> */}
                                        <h3 className="card-title" >Add Event</h3>

                                    </div>
                                    <div className="card-body row col-12" >

                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" name="eventName" className="form-control form-control-solid" placeholder="Enter event name" onChange={(event) => setstate((prevState) => ({ ...prevState, eventName: event.target.value }))} value={state.eventName} />
                                                        <span className="form-text text-danger">{state.errors?.eventName}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">

                                                        <label>Upload Banner</label>
                                                        <input type="file" name="image"
                                                            onChange={(event) => {
                                                                setstate((prevState) => ({ ...prevState, image: event.target.files[0] }))
                                                            }}
                                                            className="form-control form-control-file" data-buttonname="btn-secondary"
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Location:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedLocation}
                                                            onChange={(e) => { onSelectLocation(e) }}
                                                            options={Locations}
                                                        />

                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Event Type:</label>
                                                        <select value={state.EventType} className="form-control form-select-solid" onChange={(e) => { setstate((prevState) => ({ ...prevState, EventType: e.target.value })) }} style={{ backgroundColor: '#F3F6F9' }} aria-label="Select Event Type" >
                                                            <option value="" selected>Select Event Type</option>
                                                            <option value="Private" >Private</option>
                                                            <option value="Public" >Public</option>
                                                            <option value="Meeting" >Meeting</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Country / દેશ:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedCountry}
                                                            onChange={(e) => {
                                                                setselectedCountry(e);
                                                                setselectedDistrict({ label: "--Select--", value: "" });
                                                                setselectedCity({ label: "--Select--", value: "" });
                                                                setselectedState({ label: "--Select--", value: "" });
                                                                onGetStateList(e.value)
                                                            }}
                                                            options={countries}
                                                        />



                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>State / રાજ્ય:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedState}
                                                            onChange={(e) => {
                                                                setselectedState(e);
                                                                setselectedDistrict({ label: "--Select--", value: "" });
                                                                setselectedCity({ label: "--Select--", value: "" });
                                                                onGetDistrictList(e.value)
                                                            }}
                                                            options={stateList}
                                                        />


                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>District / જિલ્લો:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedDistrict}
                                                            onChange={(e) => { setselectedDistrict(e); setselectedCity({ label: "--Select--", value: "" }); onGetCitiesList(e.value) }}
                                                            options={districtList}
                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>City / શહેર:</label>
                                                        <Select classNamePrefix="select2-selection"
                                                            value={selectedCity}
                                                            onChange={(e) => { setselectedCity(e); }}
                                                            options={cityList}
                                                        />
                                                        <span className="form-text text-danger">{state.errors?.selectedCity}</span>



                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <input
                                                            className="form-control" type="date" placeholderText="mm / dd / yyyy"
                                                            value={state.eventStartDate}
                                                            name="eventStartDate"
                                                            onChange={(e) => { setstate((prevState) => ({ ...prevState, eventStartDate: e.target.value })); console.log(e.target.value) }}
                                                            id="eventStartDate"
                                                        />


                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Event Start Time</label>

                                                        <input type="time" name="eventStartTime" className="form-control form-control-solid" onChange={(event) => setstate((prevState) => ({ ...prevState, eventStartTime: event.target.value }))} value={state.eventStartTime} />
                                                    </div>
                                                </div>

                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <input
                                                            className="form-control" type="date" placeholderText="mm / dd / yyyy"
                                                            value={state.eventEndDate}
                                                            name="eventEndDate"
                                                            onChange={(e) => { setstate((prevState) => ({ ...prevState, eventEndDate: e.target.value })) }}
                                                            id="eventEndDate"
                                                        />


                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Event End Time</label>
                                                        <input type="time" name="eventEndTime" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => { console.log(event); setstate((prevState) => ({ ...prevState, eventEndTime: event.target.value })) }} value={state.eventEndTime} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Registration Start Date</label>
                                                        <input
                                                            className="form-control" type="date" placeholderText="mm / dd / yyyy"
                                                            value={state.registrationStartDate}
                                                            name="registrationStartDate"
                                                            onChange={(e) => { setstate((prevState) => ({ ...prevState, registrationStartDate: e.target.value })) }}
                                                            id="registrationStartDate"
                                                        />


                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Registration End Date</label>
                                                        <input
                                                            className="form-control" type="date" placeholderText="mm / dd / yyyy"
                                                            value={state.registrationEndDate}
                                                            name="registrationEndDate"
                                                            onChange={(e) => { setstate((prevState) => ({ ...prevState, registrationEndDate: e.target.value })) }}
                                                            id="registrationEndDate"
                                                        />




                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Description</label>
                                                        <textarea type="text" name="eventDescription" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => setstate((prevState) => ({ ...prevState, eventDescription: event }))} value={state.eventDescription} ></textarea>
                                                        <span className="form-text text-danger">{state.errors?.eventDescription}</span>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-6 '>
                                                        <label>Famliy Member</label><br />
                                                        <div className='row'>
                                                            <label className='mt-3 ml-4'>Adults:</label>  <div className='col-3'> <input type="number" min="0" max="10" name="eventAdult" className="form-control " onChange={(event) => { setState({ Adults: event.target.value }); Functions.onChange(event, this) }} value={state.Adults} /></div>
                                                            <label className='mt-3'>Childs:</label>    <div className='col-3'>  <input type="number" min="0" max="10" name="eventChild" className="form-control " onChange={(event) => { setState({ Childs: event.target.value }); Functions.onChange(event, this) }} value={state.Childs} /></div>
                                                            <span className="form-text text-danger">{state.errors?.eventDescription}</span>
                                                        </div>
                                                    </div> */}
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label>Max Registration</label>
                                                        <input type="number" name="MaxRegistration" className="form-control form-control-solid" onChange={(event) => { setstate((prevState) => ({ ...prevState, MaxRegistration: event.target.value })) }} value={state.MaxRegistration} />
                                                    </div>
                                                </div>
                                                <div className='col-md-2 mt-4'>
                                                    <label className="checkbox ">
                                                       
                                                        
                                                        <input
                                                            type="checkbox"
                                                            name="RegistrationOpen"
                                                            value={state.RegistrationOpen}
                                                            onChange={(event) => { (state.RegistrationOpen == "true" || state.RegistrationOpen == true ? setstate((prevState) => ({ ...prevState, RegistrationOpen: false })) : setstate((prevState) => ({ ...prevState, RegistrationOpen: true }))) }}
                                                            defaultChecked={state.RegistrationOpen}
                                                        />
                                                        <span className='mr-3 ' ></span> Registration Open </label>
                                                </div>
                                                <div className='col-md-2 mt-4'>
                                                    <label className="checkbox ">
                                                        <input type="checkbox" name="IsCanceled"
                                                            onChange={(event) => { (state.IsCanceled == "true" || state.IsCanceled == true ? setstate((prevState) => ({ ...prevState, IsCanceled: false })) : setstate((prevState) => ({ ...prevState, IsCanceled: true }))) }}
                                                            value={state.IsCanceled}
                                                            defaultChecked={state.IsCanceled}
                                                        />
                                                        <span className='mr-3 ' ></span> IsCanceled </label>
                                                </div>
                                            </div>




                                        </div>
                                    </div>

                                    <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                        <button type="button" className="btn btn-primary mb-2" onClick={(event) => onUserInvite(event)}>
                                            {id == '' || id == null || id == undefined ?
                                                <label>Add Event</label> :
                                                <label>Edit Event</label>
                                            }
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(Event);